import React, {
  useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import s from './Category.module.scss';
import DropdownRow from '../DropdownRow/DropdownRow';
import { setChoicesThunk } from '../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES } from '../../../constants/names';
import InterviewItem from '../../../pages/Interview/Templates/InterviewTemplate/components/InterviewItem/InterviewItem';
import { setChatData, setSkippedChapters } from '../../../redux/slices/currentInterviewSlice';

const Category = ({
  title,
  isActive,
  toggleCategory,
  checkbox,
  items,
  checkboxKey,
  keys,
  columnWidth,
  isInterview = false,
  skipChapter,
}) => {
  const ref = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  let maxWidth = '95%';
  const itemCountPerColumn = isBigScreen ? 8 : 5;

  const executeScroll = () => {
    toggleCategory();
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 10);
  };
  const [ columnCount, setColumnCount ] = useState(0);
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement && columnWidth) {
      const itemsCount = Math.ceil(items.length / itemCountPerColumn);
      const colCount = Math.ceil(contentElement.clientWidth / Number(columnWidth));
      if (!isMobile) {
        setColumnCount((itemsCount < colCount ? itemsCount : colCount) || 1);
        if (columnCount > 1) {
          maxWidth = `${Number(columnWidth) * columnCount > contentElement.clientWidth
            ? contentElement.clientWidth
            : Number(columnWidth) * columnCount
          }px`;
        }
      } else {
        setColumnCount(1);
        maxWidth = contentElement.clientWidth;
      }
    }
  }, [ items ]);

  const onItemClick = (item, isSkip) => {
    const id = item.id;
    const isSelected = item.selected;
    const operation = isSelected ? 0 : 1;
    const value = {
      subSection: title,
      optionId: id,
    };
    if (skipChapter && !isSkip) {
      dispatch(setSkippedChapters());
    }
    if (isInterview) dispatch(setChatData(item));
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
  };

  useEffect(() => {
    if (skipChapter) {
      items.forEach((e) => {
        if (e.selected) {
          onItemClick(e, true);
        }
      });
    }
  }, [ skipChapter ]);

  return (
    <section ref={contentRef}>
      <button
        ref={ref}
        className={classNames(s.category__button, isActive && s.active)}
        onClick={executeScroll}>
        <div className={s.category__arrow}>
          <svg className={s.category__svg}>
            <use xlinkHref='#arrow' />
          </svg>
        </div>
        <div className={s.category__title}>{title}</div>
      </button>
      <li className={s.category__item}>
        {isActive && (
          <ul
            style={{ columnCount, columnWidth: !isMobile ? `${columnWidth}px` : 'auto', width: columnCount === 1 ? '95%' : maxWidth }}
            className={classNames(s.dropdownRow__list, { [s.anamnesisDropdown__list]: !checkbox })}>
            {checkbox
              ? items.map(item => (
                <DropdownRow
                  key={item.id}
                  value={item[checkboxKey]}
                  isActive={item.selected}
                  toggleItem={() => onItemClick(item)}
                  columnWidth={isMobile ? '95%' : `${columnWidth}px`}
                />
              ))
              : items.map(item => (
                <InterviewItem
                  key={item.id}
                  keys={keys}
                  onItemClick={() => onItemClick(item)}
                  data={item}
                />
              ))}
          </ul>
        )}
      </li>
    </section>
  );
};

export default Category;
