import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import s from './ImageInteractive.module.scss';
import AdditionalExams from './components/AdditionalExams/AdditionalExams';
import PhysicalNavbar from './components/PhysicalNavbar/PhysicalNavbar';
import PhysicalInfo from './components/PhysicalInfo/PhysicalInfo';
import { EXAMINATION_TYPES, HUMAN_POSITION } from '../../../../constants/physicalConstants';
import { setChoicesThunk } from '../../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES } from '../../../../constants/names';
import PhotoPopup from '../../../../components/PhotoPopup/PhotoPopup';
import { setImageOpen, setSkippedChapters } from '../../../../redux/slices/currentInterviewSlice';
import { useTranslation } from '../../../../context/LanguageProvider';
import ZMinus from '../../../../assets/nImages/human_z_minus.svg';
import ZPlus from '../../../../assets/nImages/human_z_plus.svg';

const { BACK } = HUMAN_POSITION;

const { PHYSICAL, ADDITIONAL } = EXAMINATION_TYPES;
const allHumanPositions = Object.values(HUMAN_POSITION);

const ImageInteractive = ({ data, skipChapter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const files = useSelector(state => state.currentInterview.files);
  const {
    physical, physicalCategories, additional, additionalCategories,
  } = data.data;

  const navbarNames = Object.keys(physical);
  const AdditionalData = Object.entries(additional)[0];

  const [ activeNav, setActiveNav ] = useState(navbarNames[0]);
  const [ activeSide, setActiveSide ] = useState(HUMAN_POSITION.FRONT);
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ lastClickedBack, setLastClickedBack ] = useState(null);
  const [ lastClickedFront, setLastClickedFront ] = useState(null);
  const [ zoomSize, setZoomSize ] = useState(0);

  const lastClickedFrontRef = useRef(null);
  const lastClickedBackRef = useRef(null);

  const activeNavData = physical[activeNav];

  const onPhysicalClick = (id, isSelected, isAdditional, isBack, order, isSkip) => {
    const examination = isAdditional ? ADDITIONAL : PHYSICAL;
    const operation = isSelected ? 0 : 1;
    const value = {
      optionId: id,
      examination,
      subSection: isAdditional ? AdditionalData[0] : activeNav,
    };
    if (order) {
      value.order = order;
    }
    if (skipChapter && !isSkip) {
      dispatch(setSkippedChapters());
    }
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, value, operation }));
    const lastValue = operation ? id : null;
    !isAdditional && (isBack ? setLastClickedBack(lastValue) : setLastClickedFront(lastValue)
    );
  };

  useMemo(() => {
    if (skipChapter) {
      Object.keys(physical).forEach((key) => {
        physical[key].forEach((el) => {
          if (el?.selected) {
            onPhysicalClick(el.id, true, false, el.Side === 'Back', el.order);
          }
        });
      });
      Object.keys(additional).forEach((key) => {
        additional[key].forEach((el) => {
          if (el?.selected) {
            onPhysicalClick(el.id, true, true, el.Side === 'Back', el.order);
          }
        });
      });
    }
  }, [ skipChapter ]);

  const isTablet = useMediaQuery({
    query: '(max-width: 1300px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const getPhotoPaths = name => files.find(item => item.name === name) || null;

  const openImage = (image) => {
    setShowPhoto(getPhotoPaths(image));
    dispatch(setImageOpen(true));
  };

  const closePhoto = () => {
    setShowPhoto(null);
    dispatch(setImageOpen(false));
  };

  const zoomImage = () => {
    if (zoomSize < 3) {
      setZoomSize(zoomSize + 1);
    }
  };
  const unZoomImage = () => {
    if (zoomSize !== 0) {
      setZoomSize(zoomSize - 1);
    }
  };

  return (
    <section className={s.physical}>
      <div className={s.physical__inner}>
        <PhysicalNavbar
          navbarNames={navbarNames}
          activeNav={activeNav}
          setActiveNav={setActiveNav}
        />
        <div className={s.image_container}>
          <div className={s.physical__details}>
            <div className={s.physical__holder}>
              {isTablet && (
                <div className={s.physical__position}>
                  {allHumanPositions.map((position, idx) => (
                    <label
                      key={position}
                      onClick={() => setActiveSide(position)}
                      className={classNames(s.physical__label, {
                        [s.active]: position === activeSide,
                      })}>
                      <input type='radio' name='side' className={s.physical__radiobutton} />
                      <span className={s.physical__sideName}>{t(`${position} View`)}</span>
                    </label>
                  ))}
                </div>
              )}
              {allHumanPositions.map((item) => {
                if (!isTablet || activeSide === item) {
                  return (
                    <PhysicalInfo
                      zoom={`x${zoomSize}`}
                      key={item}
                      side={item}
                      activeNavData={activeNavData}
                      physicalCategories={physicalCategories}
                      onPhysicalClick={onPhysicalClick}
                      getPhotoPaths={getPhotoPaths}
                      openImage={openImage}
                      lastClicked={item === BACK ? lastClickedBack : lastClickedFront}
                      lastClickedRef={item === BACK ? lastClickedBackRef : lastClickedFrontRef}
                      skipChapter
                    />
                  );
                }
                return null;
              })}
            </div>
            {!isMobile && (
              <div className={s.physical_settings}>
                <label className={s.ps_label}>Silhouette Size</label>
                <div className={s.ps_options}>
                  <img
                    className={classNames(s.ps_zplus, { [s.zoom_disabled]: zoomSize === 3 })}
                    src={ZPlus}
                    alt='+'
                    onClick={zoomImage}
                  />
                  <img
                    className={classNames(s.ps_zminus, { [s.zoom_disabled]: zoomSize === 0 })}
                    src={ZMinus}
                    alt='-'
                    onClick={unZoomImage}
                  />
                </div>
              </div>
            )}
          </div>
          <AdditionalExams
            title={AdditionalData[0]}
            data={AdditionalData[1]}
            additionalCategories={additionalCategories}
            onPhysicalClick={onPhysicalClick}
            getPhotoPaths={getPhotoPaths}
            openImage={openImage}
            skipChapter
          />
        </div>
      </div>
      {showPhoto && <PhotoPopup img={showPhoto} onClose={closePhoto} />}
    </section>
  );
};

export default ImageInteractive;
