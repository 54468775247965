import { createSlice } from '@reduxjs/toolkit';
import { CHOICE_TYPES, INTERVIEW_TABS_CHAPTERS, INTERVIEW_TABS_KEYS } from '../../constants/names';
import { EXAMINATION_TYPES } from '../../constants/physicalConstants';

const { PHYSICAL, ADDITIONAL } = EXAMINATION_TYPES;
const { INTRODUCTION, SURVEY } = INTERVIEW_TABS_KEYS;

const initialState = {
  id: null,
  activeTab: INTERVIEW_TABS_CHAPTERS.INTRODUCTION,
  isNextLoading: false,
  isImageOpen: false,
  isImagesOpen: false,
  isVideoOpen: false,
  isAllFinished: false,
  translation: {},
  keyWords: {},
  chatData: [],
  skippedChapters: [],
};

export const currentInterviewSlice = createSlice({
  name: 'currentInterview',
  initialState,
  reducers: {
    setInterview: (state, { payload }) => ({ ...state, ...payload }),
    resetInterview: (state) => {
      state.isNextLoading = false;
      state.isAllFinished = false;
      state.isImagesOpen = false;
      state.isImagesOpen = false;
      state.isVideoOpen = false;
      state.chatData = [];
      state.skippedChapters = [];
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      if (payload !== INTRODUCTION && payload !== SURVEY) {
        const sectionId = state.sheetContent.findIndex(e => e.title === payload);
        state.sheetContent[sectionId]['visited'] = true;
      }
    },
    setNextLoading: (state, { payload }) => {
      state.isNextLoading = payload;
    },
    setImageOpen: (state, { payload }) => {
      state.isImageOpen = payload;
    },
    setImagesOpen: (state, { payload }) => {
      state.isImagesOpen = payload;
    },
    setVideoOpen: (state, { payload }) => {
      state.isVideoOpen = payload;
    },
    setSelected: (state, { payload }) => {
      const {
        section, subSection, optionId, examination, operation, order, isNone,
      } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      if (optionId === 'all') {
        Object.values(state.sheetContent[sectionId].data.answers).forEach((category) => {
          category.forEach((el) => {
            if (isNone === undefined) {
              el.selected = !!operation;
              return;
            }
            if (!isNone.includes(el.id)) {
              el.selected = !!operation;
            }
          });
        });
        state.sheetContent[sectionId].started = !!operation;
      } else {
        const dataFolder = examination || 'answers';
        const itemId = state.sheetContent[sectionId].data[dataFolder][subSection].findIndex(
          item => item.id === optionId,
        );
        if (itemId === -1) return;
        if (isNone && operation) {
          Object.values(state.sheetContent[sectionId].data.answers).forEach((category) => {
            category.forEach((el) => {
              el.selected = false;
            });
          });
        }
        state.sheetContent[sectionId].data[dataFolder][subSection][itemId].selected = !!operation;
        if (order) {
          state.sheetContent[sectionId].data[dataFolder][subSection][itemId].order = order;
        }
        if (operation) {
          state.sheetContent[sectionId].started = true;
          if (state.sheetContent[sectionId]['selectionCount'] === undefined) {
            state.sheetContent[sectionId]['selectionCount'] = 0;
          }
          state.sheetContent[sectionId]['selectionCount'] += 1;
        } else {
          state.sheetContent[sectionId]['selectionCount'] -= 1;
          const allData = Object.values(state.sheetContent[sectionId].data[dataFolder]);
          let isItemSelected = false;
          for (let i = 0; i < allData.length; i += 1) {
            for (let j = 0; j < allData[i].length; j += 1) {
              if (allData[i][j].selected) {
                isItemSelected = true;
                break;
              }
            }
          }
          if (examination) {
            const otherExamination = examination === PHYSICAL ? ADDITIONAL : PHYSICAL;
            const otherData = Object.values(state.sheetContent[sectionId].data[otherExamination]);
            for (let i = 0; i < otherData.length; i += 1) {
              for (let j = 0; j < otherData[i].length; j += 1) {
                if (otherData[i][j].selected) {
                  isItemSelected = true;
                  break;
                }
              }
            }
          }
          state.sheetContent[sectionId].started = isItemSelected;
        }
      }
    },
    setRowSelected: (state, { payload }) => {
      const {
        section, optionId, operation, isNone,
      } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      if (optionId === 'all') {
        state.sheetContent[sectionId].data.answers.forEach((el) => {
          if (isNone === undefined) {
            el.selected = !!operation;
            return;
          }
          if (!isNone.includes(el.id)) {
            el.selected = !!operation;
          }
        });
      } else {
        const itemId = state.sheetContent[sectionId].data.answers.findIndex(
          item => item.id === optionId,
        );
        if (itemId === -1) return;
        if (isNone && operation) {
          state.sheetContent[sectionId].data.answers.forEach((answer) => {
            answer.selected = false;
          });
        }
        state.sheetContent[sectionId].data.answers[itemId].selected = !!operation;
      }
      if (operation) {
        state.sheetContent[sectionId].started = true;
        if (state.sheetContent[sectionId]['selectionCount'] === undefined) {
          state.sheetContent[sectionId]['selectionCount'] = 0;
        }
        state.sheetContent[sectionId]['selectionCount'] += 1;
      } else {
        state.sheetContent[sectionId]['selectionCount'] -= 1;
        const allData = state.sheetContent[sectionId].data.answers;
        let isItemSelected = false;
        for (let i = 0; i < allData.length; i += 1) {
          if (allData[i].selected) {
            isItemSelected = true;
            break;
          }
        }
        if (state.sheetContent[sectionId].data.comment) {
          isItemSelected = true;
        }
        state.sheetContent[sectionId].started = isItemSelected;
      }
    },
    setSectionFinished: (state, { payload }) => {
      const { section, nextTab } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      state.activeTab = nextTab;
      state.sheetContent[sectionId].finished = true;
      if (nextTab) {
        const nextSectionId = state.sheetContent.findIndex(sheet => sheet.title === nextTab);
        if (nextSectionId === -1) return;
        state.sheetContent[nextSectionId].visited = true;
      }
    },
    setSectionStarted: (state, { payload }) => {
      const { section, operation } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      state.sheetContent[sectionId].started = operation;
    },
    setComment: (state, { payload }) => {
      const { section, operation, comment } = payload;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === section);
      if (sectionId === -1) return;
      const value = operation ? comment : '';
      state.sheetContent[sectionId].data.comment = value;
      if (operation) {
        state.sheetContent[sectionId].started = true;
      } else {
        const allData = state.sheetContent[sectionId].data.answers;
        let isItemSelected = false;
        for (let i = 0; i < allData.length; i += 1) {
          if (allData[i].selected) {
            isItemSelected = true;
            break;
          }
        }
        state.sheetContent[sectionId].started = isItemSelected;
      }
    },
    setRate: (state, { payload }) => {
      const { type, rate } = payload;
      const currentField = type === CHOICE_TYPES.APP_RATE ? 'appRate' : 'rate';
      state[currentField] = rate;
    },
    setFeedback: (state, { payload }) => {
      state.feedback = payload;
    },
    finishCase: (state) => {
      state.finished = true;
      state.isAllFinished = true;
    },
    dragQuestion: (state, { payload }) => {
      const { sourceI, destI } = payload;
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(sheet => sheet.title === activeTab);
      if (sectionId === -1) return;
      const orders = state.sheetContent[sectionId].data.orders
        || state.sheetContent[sectionId].data.answers.map(answer => answer.id);
      const questionsArr = [ ...orders ];
      const [ selectedRow ] = questionsArr.splice(sourceI, 1);
      questionsArr.splice(destI, 0, selectedRow);
      state.sheetContent[sectionId].data.orders = questionsArr;
    },
    toggleShareable: (state, { payload }) => {
      state.shareable = payload;
    },
    setCaseStarted: (state) => {
      state.started = true;
    },
    setChatData: (state, { payload }) => {
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(e => e.title === activeTab);
      if (state.sheetContent[sectionId].chatData === undefined) {
        state.sheetContent[sectionId].chatData = [];
      }
      const savedChats = state.sheetContent[sectionId].chatData;
      if (state.sheetContent[sectionId].chatData.length > 0) {
        state.sheetContent[sectionId].chatData = savedChats.filter(el => el.id !== payload.id);
      }
      if (state.sheetContent[sectionId].chatData.length === savedChats.length) {
        state.sheetContent[sectionId].chatData.push(payload);
      }
    },
    resetChatData: (state) => {
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(e => e.title === activeTab);
      state.sheetContent[sectionId].chatData = [];
    },
    setChatperCommentCount: (state, { payload }) => {
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(e => e.title === activeTab);
      state.sheetContent[sectionId]['commentCount'] = payload;
      if (state.sheetContent[sectionId]['selectionCount'] === undefined) {
        state.sheetContent[sectionId]['selectionCount'] = 0;
      }
      state.sheetContent[sectionId]['selectionCount'] += payload !== 0 ? 1 : 0;
    },
    setSkippedChapters: (state) => {
      const { activeTab } = state;
      const sectionId = state.sheetContent.findIndex(e => e.title === activeTab);
      if (!state.skippedChapters.includes(activeTab)) {
        state.skippedChapters.push(activeTab);
        state.sheetContent[sectionId].skipChapter = true;
      } else {
        state.sheetContent[sectionId].skipChapter = false;
        state.skippedChapters = state.skippedChapters.filter(e => e !== activeTab);
      }
    },
  },
});

// ACTIONS
export const {
  setInterview,
  resetInterview,
  setActiveTab,
  setSelected,
  dragQuestion,
  setSectionStarted,
  setSectionFinished,
  setRowSelected,
  setComment,
  setRate,
  setFeedback,
  finishCase,
  setNextLoading,
  setImageOpen,
  setImagesOpen,
  setVideoOpen,
  toggleShareable,
  setCaseStarted,
  setChatData,
  resetChatData,
  setSkippedChapters,
  setChatperCommentCount,
} = currentInterviewSlice.actions;

// SELECTORS
export const selectCurrentInterview = state => state.currentInterview;
export const selectInterviewId = state => state.currentInterview.id;
export const selectInterviewTranslationId = state => state.currentInterview.tId;
export const selectActiveTab = state => state.currentInterview.activeTab;
export const selectTranslation = state => state.currentInterview.translation;
export const selectKeywords = state => state.currentInterview.keyWords;

export default currentInterviewSlice.reducer;
