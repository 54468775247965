import React from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import s from './DragRow.module.scss';
import upArrow from '../../../../../../assets/nImages/upArrow.png';
import downArrow from '../../../../../../assets/nImages/downArrow.png';

const DragRow = ({
  number, children, item, index, noOfQuestions, onClickDrag, rankUp, rankDown,
}) => {
  const moveItemUp = () => {
    if (index - 1 >= 0) {
      const params = {
        source: { index },
        destination: { index: index - 1 },
      };
      onClickDrag(params);
    }
  };
  const moveItemDown = () => {
    if (index + 1 < noOfQuestions) {
      const params = {
        source: { index: index + 1 },
        destination: { index },
      };
      onClickDrag(params);
    }
  };
  return (<Draggable
    key={item.id}
    draggableId={`draggable-${item.id}`}
    index={index}>
    {dragProvided => (
      <div
        ref={dragProvided.innerRef}
        {...dragProvided.draggableProps}
        {...dragProvided.dragHandleProps}
        id={index + item.id}
        className={classNames(s.dragRow,
          s.drag,
          {
            [s.rankUp]: rankUp,
            [s.rankDown]: rankDown,
          })}>
        {number && (
          <div className={s.cardRow__number}>{number}</div>
        )}
        <div className={s.cardRow__info}>
          <div className={s.cardRow__text}>{children}</div>
        </div>
        <div className={s.cardRow__drag}>
          <div className={s.cardRow_drag_container}>
            {index !== 0 && <img
              onClick={moveItemUp}
              className={classNames(s.cardRow__drag__icon)}
              src={upArrow}
              alt='upArrow' />}
            {index !== noOfQuestions - 1 && <img
              onClick={moveItemDown}
              className={classNames(s.cardRow__drag__icon)}
              src={downArrow}
              alt='downArrow' />}
            <svg className={classNames(s.cardRow__drag__handle)}>
              <use xlinkHref="#drag" />
            </svg>
          </div>
        </div>
      </div>
    )}
  </Draggable>);
};
export default DragRow;
