import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import s from './Ranking.module.scss';
import QuestionSet2Summary from './components/QuestionSet2Summary/QuestionSet2Summary';
import DragRow from './components/DragRow/DragRow';
import { dragQuestion } from '../../../../redux/slices/currentInterviewSlice';

const Ranking = ({ isSummaryPage, data }) => {
  const dispatch = useDispatch();
  const { answers, orders } = data.data;
  const [ rankUpIndex, setRankUpIndex ] = useState(-1);
  const [ rankDownIndex, setRankDownIndex ] = useState(-1);

  const dragHandler = (e) => {
    if (!e.destination) return;
    const { destination, source } = e;
    dispatch(dragQuestion({ sourceI: source.index, destI: destination.index }));
  };

  const clickDragHandler = (e) => {
    if (!e.destination) return;
    const { destination, source } = e;
    setRankDownIndex(destination.index);
    setRankUpIndex(source.index);
    setTimeout(() => {
      dispatch(dragQuestion({ sourceI: source.index, destI: destination.index }));
      setRankUpIndex(-1);
      setRankDownIndex(-1);
    }, 500);
  };

  const sortedQuestions = useMemo(() => (
    orders ? [ ...answers ].sort((a, b) => orders.indexOf(a.id) - orders.indexOf(b.id)) : answers
  ), [ answers, orders ]);

  return (
    <section className={s.questionSet2}>
      {isSummaryPage ? (
        <QuestionSet2Summary data={sortedQuestions} />
      ) : (
        <DragDropContext onDragEnd={dragHandler}>
          <Droppable droppableId='droppable-1'>
            {(provided, _) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={s.questionSet2__list}>
                {sortedQuestions.map((item, i) => (
                  <DragRow
                    key={item.id}
                    item={item}
                    index={i}
                    noOfQuestions={sortedQuestions.length}
                    drag
                    rankUp={rankUpIndex === i}
                    rankDown={rankDownIndex === i}
                    onClickDrag={clickDragHandler}
                    number={i + 1}>
                    {item.value?.split('\n').map((paragraph, idx) => (
                      <p key={idx} className='paragraph'>{paragraph}</p>
                    ))}
                  </DragRow>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </section>
  );
};

export default Ranking;
