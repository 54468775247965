import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { INTERVIEW_TABS_CHAPTERS } from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { getInterviewIdFromUrl, getUuidFromUrl } from '../../helpers/utils';
import { setAlert } from '../slices/alertSlice';
import {
  resetInterview,
  setInterview,
} from '../slices/currentInterviewSlice';

const { INTRODUCTION } = INTERVIEW_TABS_CHAPTERS;

export const getEventThunk = createAsyncThunk(
  'event/getEvent',
  async ({ uuid = getUuidFromUrl(), reset }, { dispatch }) => {
    const doctor_token = localStorage.getItem(uuid) || '';

    const response = await AXIOS.post('/client/getEvent', { uuid }, {
      headers: {
        'doctor-token': doctor_token,
      },
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    const { token, event } = response.data;
    if (!doctor_token) {
      localStorage.setItem(uuid, token);
    }
    AXIOS.defaults.headers.common['doctor-token'] = token;
    const interviewId = getInterviewIdFromUrl();
    if (interviewId) {
      const currentEvent = event.cases.find(item => item.id === interviewId);
      if (currentEvent) {
        let activeTab = INTRODUCTION;
        if (currentEvent.started && !currentEvent.finished && !reset) {
          // activeTab = SURVEY;
          const tabs = currentEvent.sheetContent;
          currentEvent.sheetContent.map((el) => {
            el.skipChapter = false;
            el.visited = false;
            el.hasSelection = false;
            el.selectionCount = 0;
            el.chatData = [];
            return el;
          });
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < tabs.length; i++) {
            if (!tabs[i].finished) {
              activeTab = tabs[i].title;
              break;
            }
          }
        }
        // response.data.sheetContent.map((e) => {
        //   e.skipChapter = false;
        //   return e;
        // });
        dispatch(setInterview({ activeTab, ...currentEvent }));
      }
    }
    return response.data;
  },
);

const getEventThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getEventThunkFulfilled = (state, { payload }) => ({
  ...state,
  isConnected: true,
  token: payload.token,
  ...payload.event,
});

const getEventThunkRejected = (state, { error }) => {
  state.isConnected = true;
  state.uuid = getUuidFromUrl();
  state.isLoading = false;
};

export const getCaseThunk = createAsyncThunk(
  'event/getCase',
  async ({ uuid = getUuidFromUrl() }, { dispatch }) => {
    const [ urlId, urlLanguage ] = uuid.split('&');
    const id = urlId.split('case=')[1];
    const language = urlLanguage?.split('language=')[1];
    const response = await AXIOS.post('/client/getCase', { uuid: id, language }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    response.data.sheetContent.map((e) => {
      e.skipChapter = false;
      e.visited = false;
      e.selectionCount = 0;
      e.chatData = [];
      return e;
    });
    dispatch(setInterview({ activeTab: INTRODUCTION, ...response.data }));
    return response.data;
  },
);

const getCaseThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getCaseThunkFulfilled = (state) => {
  state.isConnected = true;
  state.isOnlyCase = true;
};

const getCaseThunkRejected = (state) => {
  state.isConnected = true;
  state.uuid = getUuidFromUrl();
  state.isLoading = false;
};

// RESET CASE

export const resetCaseThunk = createAsyncThunk(
  'event/resetCase',
  async (data, { dispatch, getState }) => {
    const modEvent = getState().event;
    if (!modEvent.isOnlyCase) {
      const caseId = modEvent?.cases[0]?.tId;
      await AXIOS.post('/client/reset', { caseId }).catch((err) => {
        dispatch(setAlert({ message: getErrorMessage(err) }));
        throw Error(err);
      });
      dispatch(resetInterview());
      dispatch(getEventThunk({ reset: true }));
    } else {
      dispatch(resetInterview());
      dispatch(getCaseThunk({ uuid: getUuidFromUrl() }));
    }
  },
);

export const eventExtraReducers = (builder) => {
  builder
    .addCase(getEventThunk.pending, getEventThunkPending)
    .addCase(getEventThunk.fulfilled, getEventThunkFulfilled)
    .addCase(getEventThunk.rejected, getEventThunkRejected)
    .addCase(getCaseThunk.pending, getCaseThunkPending)
    .addCase(getCaseThunk.fulfilled, getCaseThunkFulfilled)
    .addCase(getCaseThunk.rejected, getCaseThunkRejected)
    .addCase(resetCaseThunk.pending, (state) => { state.isStartOverLoading = true; })
    .addCase(resetCaseThunk.fulfilled, (state) => { state.isStartOverLoading = false; })
    .addCase(resetCaseThunk.rejected, (state) => { state.isStartOverLoading = false; });
};
