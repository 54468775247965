import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stars from '../../../../../components/Stars/Stars';
import { CHOICE_TYPES } from '../../../../../constants/names';
import { useTranslation } from '../../../../../context/LanguageProvider';
import { setChoicesThunk } from '../../../../../redux/thunks/interviewThunk';
import s from '../Survey.module.scss';

const { RATE, APP_RATE } = CHOICE_TYPES;

const SurveyItem = ({ title, app }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rate = useSelector(state => state.currentInterview.rate);
  const appRate = useSelector(state => state.currentInterview.appRate);
  const currentRate = app ? appRate : rate;

  const setChoice = useCallback(
    debounce((star) => {
      const value = {
        rate: star,
      };
      const type = app ? APP_RATE : RATE;
      dispatch(setChoicesThunk({ type, value }));
    }, 250),
    [ app ],
  );

  const onStarClick = (star) => {
    setChoice(star);
  };
  return (
    <div className={s.survey__item}>
      <div className={s.survey__header}>
        <div className={s.survey_card}>{t(title)}</div>
      </div>
      <div className={s.survey__assessment}>
        <div className={s.survey__satisfied}>
          <div className={s.survey__value}>{t('Not satisfied')}</div>
          <div className={s.survey__value}>{t('Very satisfied')}</div>
        </div>
        <Stars value={currentRate} onStarClick={onStarClick} />
      </div>
    </div>
  );
};

export default SurveyItem;
