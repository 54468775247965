import React from 'react';
import s from './QuestionSet2Summary.module.scss';
import CardRow from '../../../../../../components/UI/CardRow/CardRow';

const QuestionSet2Summary = ({ data }) => (
  <div className={s.questionSet2Summary}>
    <ul className={s.questionSet2Summary__list}>
      {data.map((item, i) => (
        <CardRow key={item.id} checked number={i + 1}>
          {item.value?.split('\n').map((paragraph, idx) => (
            <p key={idx} className='paragraph'>{paragraph}</p>
          ))}
        </CardRow>
      ))}
    </ul>
  </div>
);

export default QuestionSet2Summary;
