import classNames from 'classnames';
import React from 'react';
import { INTERVIEW_TABS_KEYS, QUESTION_KEYS } from '../../constants/names';
import { useTranslation } from '../../context/LanguageProvider';
import s from './Heading.module.scss';

const { CHAPTER_NAME, QUESTION } = QUESTION_KEYS;
const { INTRODUCTION } = INTERVIEW_TABS_KEYS;

function formatRankingQuestion(question, answerCount) {
  const arr = question.split('(');
  if (arr && arr.length > 1) {
    arr[1] = arr[1]
      .replace(';', '<br />')
      .replace('(', '')
      .replace(')', '')
      .replace(':', '&nbsp;:');
  } else {
    return [ arr[0], `Position 1 – Most important <br /> Position ${answerCount} – Least important` ];
  }
  return arr;
}

const Heading = ({ data, headingImage, isRanking }) => {
  const { t } = useTranslation();
  let formattedQuestion = '';
  if (isRanking) {
    formattedQuestion = formatRankingQuestion(t(data.data.question[QUESTION]),
      data.data.answers.length);
  }
  return (
    <div className={classNames(s.heading, { [s.ranking]: isRanking })}>
      <div className={s.heading__present}>
        {data.data.question[CHAPTER_NAME] !== INTRODUCTION
        && <div className={s.heading__title}>{t(data.data.question[CHAPTER_NAME])}</div>}
        {!isRanking ? (
          <div className={s.heading__subtitle}>{t(data.data.question[QUESTION])}</div>
        ) : (
          <div className={s.heading__subtitle}>
            <div>{formattedQuestion[0]}</div>
            <div
              className={s.heading__ranking_helpers}
              dangerouslySetInnerHTML={{ __html: formattedQuestion[1] }} />
          </div>
        )}
      </div>
      <div className={classNames(s.heading__picture, { [s.ranking]: isRanking })}>
        {headingImage && <img className={s.heading__img} src={headingImage} alt='' />}
      </div>
    </div>
  );
};

export default Heading;
