import React from 'react';
import s from './Survey.module.scss';
import SurveyItem from './components/SurveyItem';
import SurveyComments from './components/SurveyComments';

const Survey = () => (
  <section className={s.survey}>
    <section className={s.survey_container}>
      <SurveyItem title='Patient Case Presented' />
      <SurveyItem title='Application (MedQA) used for presenting this Patient Case' app />
    </section>
    <section className={s.comment_container}>
      <SurveyComments title='Write your own feedback.' />
    </section>
  </section>
);

export default Survey;
