import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import s from './Header.module.scss';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { resetCaseThunk } from '../../redux/thunks/eventThunk';
import { selectActiveTab, selectInterviewTranslationId } from '../../redux/slices/currentInterviewSlice';
import { getisInterviewPage } from '../../helpers/utils';
import { selectEvent } from '../../redux/slices/eventSlice';
import { useTranslation } from '../../context/LanguageProvider';
import { Button } from '../UI';
import medqpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import { INTERVIEW_TABS_KEYS } from '../../constants/names';

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const caseId = useSelector(selectInterviewTranslationId);
  const event = useSelector(selectEvent);
  const [ isConfirmActive, setIsConfirmActive ] = useState(false);
  const activeTab = useSelector(selectActiveTab);
  const isInterviewPage = getisInterviewPage(pathname);
  const isStartOverVisible = isInterviewPage
    && caseId && activeTab !== INTERVIEW_TABS_KEYS.INTRODUCTION;

  const openConfirm = () => {
    setIsConfirmActive(true);
  };

  const startOver = () => {
    dispatch(resetCaseThunk({ caseId })).unwrap().then(() => setIsConfirmActive(false));
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const isTablet = useMediaQuery({
    query: '(max-width: 1300px)',
  });

  return (
    <header className={s.header}>
      {(isTablet || isMobile) && (
        <div className={s.headerInfo}>
          <div className={s.headerMedQALogo}>
            <div
              className={classNames(s.headerLogo__icon)}>
              <img className={s.welcome__img} src={medqpLogo} alt='MedQP Logo' />
            </div>
          </div>
        </div>
      )}
      <div className={s.headerInfo}>
        {isStartOverVisible && (
          <div className={s.headerStart}>
            <Button onClick={openConfirm} variant='grey'>
              {t('Start Over')}
            </Button>
          </div>
        )}
        {!isStartOverVisible && !event.isOnlyCase && <div className={s.headerStart} />}
        {event.id && !event.isOnlyCase && isInterviewPage && (
          <section className={s.headerEventInfo}>
            <div className={s.headerText}>
              <p className={s.headerText__eventName}>{event.name}</p>
              <p className={s.headerText__eventDetails}>
                {event.city && `${event.city}, `}
                {event.country} ({event.region}) - {dayjs(event.startDate).format('MMM D, YYYY')}
              </p>
            </div>
          </section>
        )}
      </div>
      <div className={s.headerInfo}>
        <div className={s.headerPharmaceutical}>
          <div
            className={classNames(s.headerPharmaceutical__icon, {
              [s.header_custom_logo_bi]: !event?.isOnlyCase && event && event?.eventLogo,
            })}>
            {!event?.isOnlyCase && event && event?.eventLogo && (<img
              className={s.welcome__img}
              src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventLogo}`}
              alt='BoehringerIngelheim'
            />)}
          </div>
        </div>
      </div>
      {/* MODAL */}
      {isConfirmActive && (
        <ConfirmModal
          onConfirm={startOver}
          onClose={() => setIsConfirmActive(false)}
          loading={event?.isStartOverLoading}
        />
      )}
    </header>
  );
};

export default Header;
