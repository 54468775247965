import React from 'react';
import { useSelector } from 'react-redux';
import s from './Introduction.module.scss';
import PatientCasesCard from '../../components/PatientCasesCard/PatientCasesCard';
import { selectCurrentInterview } from '../../redux/slices/currentInterviewSlice';

const Introduction = () => {
  const cardData = useSelector(selectCurrentInterview);
  return (
    <section className={s.introContainer}>
      <div className={s.introLeftContainer}>
        <PatientCasesCard isPCList={false} data={cardData} selected />
      </div>
    </section>
  );
};

export default Introduction;
