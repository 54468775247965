import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import s from './PatientCases.module.scss';
import PatientCasesCard from '../../components/PatientCasesCard/PatientCasesCard';
import { useTranslation } from '../../context/LanguageProvider';
import medqpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import welcomeIcon from '../../assets/images/welcome.svg';

const PatientCases = () => {
  const cases = useSelector(state => state.event.cases);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  return (
    <section className={s.patientCases} >
      <div className={s.pc_header}>
        {isMobile && <div className={s.pc_header_icon}>
          <img className={s.welcome__img} src={medqpLogo} alt='MedQP Logo' />
        </div>}
        <div className={s.pc_header_icon}>
          <img className={s.welcome__img} src={welcomeIcon} alt='Boehringer Ingelheim' />
        </div>
      </div>
      <div className={s.pc_body}>
        <div className={s.pc_body_header}>
          {!isMobile && <div className={s.pc_header_icon}>
            <img className={s.welcome__img} src={medqpLogo} alt='MedQP Logo' />
          </div>}
          <div className={s.pc_header_title}>{t('Available Patient Cases')}</div>
        </div>
        <div className={s.pc_body_container}>
          <div className={s.pc_list}>
            {cases?.map(item => (
              <div
                key={item.id}
                className={s.pc_item}><PatientCasesCard isPCList data={item} /></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatientCases;
