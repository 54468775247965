import { React, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import s from './NoCategory.module.scss';
import DropdownRow from '../DropdownRow/DropdownRow';
import { setChoicesThunk } from '../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES } from '../../../constants/names';
import InterviewItem from '../../../pages/Interview/Templates/InterviewTemplate/components/InterviewItem/InterviewItem';
import { setChatData, setSkippedChapters } from '../../../redux/slices/currentInterviewSlice';

const NoCategory = ({
  title, checkbox, items, checkboxKey, keys, skipChapter, isInterview,
}) => {
  const dispatch = useDispatch();

  const onItemClick = (item, isSkip) => {
    const id = item.id;
    const isSelected = item.selected;
    const operation = isSelected ? 0 : 1;
    const value = {
      subSection: title, optionId: id,
    };
    if (skipChapter && !isSkip) {
      dispatch(setSkippedChapters());
    }
    if (isInterview) dispatch(setChatData(item));
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
  };

  useEffect(() => {
    if (skipChapter) {
      items.forEach((e) => {
        if (e.selected) {
          onItemClick(e, true);
        }
      });
    }
  }, [ skipChapter ]);

  return (
    <li className={s.category__item}>
      <ul className={classNames(s.dropdownRow__list, { [s.anamnesisDropdown__list]: !checkbox })}>
        {checkbox
          ? items.map(item => (
            <DropdownRow
              key={item.id}
              value={item[checkboxKey]}
              isActive={item.selected}
              toggleItem={() => onItemClick(item)} />
          ))
          : items.map(item => (
            <InterviewItem
              key={item.id}
              keys={keys}
              onItemClick={() => onItemClick(item)}
              data={item} />
          ))}
      </ul>
    </li>
  );
};

export default NoCategory;
