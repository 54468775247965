import { EVENT_TYPE_IDS } from './ids';

const { VIRTUAL, FACE, BOTH } = EVENT_TYPE_IDS;

export const CHOICE_TYPES = {
  CASE: 'case',
  SECTION: 'section',
  OPTION: 'option',
  COMMENT: 'comment',
  RATE: 'rate',
  APP_RATE: 'appRate',
  FEEDBACK: 'feedback',
  PRESENTATION: 'presentation',
};

export const GENDER_TYPES = {
  MR: 'Mr.',
  MRS: 'Mrs.',
  MS: 'Ms.',
};

export const GENDER_NAMES = {
  [GENDER_TYPES.MR]: 'Male',
  [GENDER_TYPES.MRS]: 'Female',
  [GENDER_TYPES.MS]: 'Female',
};

export const EVENT_TYPE_NAMES = {
  [VIRTUAL]: 'Virtual',
  [FACE]: 'In-person',
  [BOTH]: 'Both',
};

export const INTERVIEW_TABS_CHAPTERS = {
  INTRODUCTION: 'Introduction',
  SURVEY: 'Survey',
};

export const INTERVIEW_TABS_KEYS = {
  INTRODUCTION: 'Introduction',
  TREATMENT: 'Treatment',
  SURVEY: 'Survey',
  LABORATORY: 'Laboratory',
};

export const TEMPLATE_TYPES = {
  INTERVIEW: 'interview',
  TABLE_VIEW: 'noGroups',
  TABLE_VIEW_GROUPS: 'groups',
  PRESENTATION: 'presentation',
  IMAGE_INTERACTIVE: 'imageInteractive',
  MULTIPE_CHOICE: 'multipleChoice',
  MULTIPLE_CHOICE_GROUPS: 'mcGroups',
  RANKING: 'ranking',
};

export const CHAPTER_TYPES = {
  QUESTION_ANSWERS: 'QUESTION-ANSWERS',
  QUESTION_PHYSICAL: "QUESTION-PHYS EXAM-ADD'L EXAM",
  QUESTION_LAB_TEST: 'QUESTION-LAB-TEST',
};

export const QUESTION_KEYS = {
  CHAPTER_TYPE: 'chapterType',
  CHAPTER_NAME: 'chapterName',
  QUESTION: 'chapterQuestion',
  ICON: 'icon',
  MENU_IMAGE: 'menuImage',
  BANNER_IMAGE: 'bannerImage',
  SELECT_ALL: 'haveSelectAll',
  ALLOW_WRITE: 'allowWriteIns',
  ALLOW_WRITE_TEXT: 'allowWriteInsText',
  COLUMN_WIDTH: 'columnWidth',
  SKIP_CHAPTER: 'skipChapter',
  SKIP_CHAPTER_TEXT: 'skipChapterText',
};

export const VISIT_NAMES = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
};

export const ALERT_TYPES = {
  WARRNING: 'warrning',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const PATIENT_BASE_INFO_KEYS = {
  GENDER: 'gender',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  AGE: 'age',
  VISIT: 'visit',
  SYMPTOMS: 'symptoms',
  SYMPTOMS_LABEL: 'symptomsLabel',
  HISTORY: 'history',
  HISTORY_LABEL: 'historyLabel',
  VISIT_KEY: 'patientVisitLabel',
  DISPLAY_NAME: 'chapterDisplayName',
};
