import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import s from './MultipeChoice.module.scss';
import DropdownRow from '../../../../components/UI/DropdownRow/DropdownRow';
import MultipeChoiceSummary from './component/MultipeChoiceSummary/MultipeChoiceSummary';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';
import { setChoicesThunk } from '../../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES, QUESTION_KEYS } from '../../../../constants/names';
import { selectActiveTab, setSectionStarted, setSkippedChapters } from '../../../../redux/slices/currentInterviewSlice';
import { useTranslation } from '../../../../context/LanguageProvider';

const {
  SELECT_ALL, ALLOW_WRITE, COLUMN_WIDTH, ALLOW_WRITE_TEXT,
} = QUESTION_KEYS;

const MultipeChoice = ({ isSummaryPage, data, skipChapter }) => {
  const dispatch = useDispatch();
  const { t, key_t } = useTranslation();
  const activeTab = useSelector(selectActiveTab);
  const { question, answers, comment = '' } = data.data;
  const [ commentInput, setCommentInput ] = useState(comment);
  const isSelectAllExist = question[SELECT_ALL] === key_t('YES');
  const isWriteAllow = question[ALLOW_WRITE];
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  const [ hasOverflowX, setHasOverflowX ] = useState(false);
  const [ columnWidth, setColumnWidth ] = useState('100%');

  useMemo(() => {
    setTimeout(() => {
      const questionSet = document.querySelector('#questionSet1__list');
      if (questionSet) {
        setHasOverflowX(
          questionSet.scrollWidth > questionSet.clientWidth
          || questionSet.scrollWidth === questionSet.clientWidth,
        );
        if (answers.length > 10) {
          setColumnWidth(isMobile ? '95%' : `${question[COLUMN_WIDTH]}px` || '200px');
        } else if (answers.length * 14 < questionSet.clientHeight) {
          setColumnWidth('95%');
        } else {
          setColumnWidth(
            (!isMobile && question[COLUMN_WIDTH] < questionSet.clientWidth) ? `${question[COLUMN_WIDTH]}px` || 'auto' : '95%',
          );
        }
      }
    }, 10);
  }, [ question ]);

  useEffect(() => {
    setCommentInput(comment);
  }, [ activeTab ]);

  const {
    selectedItems, isNoneSelected, noneId, noneIds, isAllSelected,
  } = useMemo(() => {
    const selected_items = [];
    let is_none_selected = false;
    let none_id = -1;
    const exclusivity_ids = [];
    answers.forEach((item) => {
      if (item.selected) {
        selected_items.push(item);
      }
      if (item.value.includes('***')) {
        if (item.selected) {
          none_id = item.id;
          is_none_selected = item.selected;
        }
        exclusivity_ids.push(item.id);
      }
    });
    return {
      selectedItems: selected_items,
      isNoneSelected: is_none_selected,
      noneId: none_id,
      noneIds: exclusivity_ids,
      isAllSelected:
        selected_items.length + exclusivity_ids.length === answers.length
        && exclusivity_ids.length !== answers.length,
    };
  }, [ answers ]);

  const onItemClick = (id, isSelected, isNone) => {
    const operation = isSelected ? 0 : 1;
    const value = {
      optionId: id,
      isNone: id !== 'all' ? isNone : noneIds,
    };
    if (skipChapter) {
      dispatch(setSkippedChapters());
    }
    if (!isNone && isNoneSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: noneId },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (noneIds.length < 2 && isNone && id === noneId && !isSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: 'all', isNone: false },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else if (noneIds.length > 1 && noneIds.includes(id) && !isSelected) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: 'all', isNone: false },
      })).then(() => {
        dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
      });
    } else {
      dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
    }
  };

  useEffect(() => {
    if (skipChapter) {
      dispatch(setChoicesThunk({
        type: CHOICE_TYPES.OPTION, operation: 0, value: { optionId: 'all', isNone: false },
      }));
      dispatch(setChoicesThunk({ type: CHOICE_TYPES.COMMENT, operation: 0, comment: '' }));
      setCommentInput('');
    }
  }, [ skipChapter ]);

  const toggleComment = () => {
    if (commentInput === comment) return;
    if (skipChapter) {
      dispatch(setSkippedChapters());
    }
    const operation = commentInput ? 1 : 0;
    const value = {
      comment: commentInput,
    };
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.COMMENT, operation, value }));
  };

  useEffect(() => {
    if (commentInput && !selectedItems.length) {
      dispatch(setSectionStarted({ section: activeTab, operation: true }));
    } else if (!commentInput && !selectedItems.length) {
      dispatch(setSectionStarted({ section: activeTab, operation: false }));
    }
  }, [ commentInput ]);

  return (
    <section className={s.questionSet1}>
      {isSummaryPage ? (
        <MultipeChoiceSummary
          comment={comment}
          selectedItems={selectedItems}
          title={data.title}
          noneIds={noneIds} />
      ) : (
        <>
          {isSelectAllExist && <div className={s.questionSet1__selectAll}>
            <div className={s.questionSet1__selectInner}>
              <Checkbox
                checked={isAllSelected}
                onChange={() => onItemClick('all', isAllSelected)}
                title={t(`${isAllSelected ? 'Remove' : 'Select'} All`)}
                disabled={(noneId !== null && noneId !== -1
                  && !isAllSelected) || noneIds.length === answers.length}
                isDisabled={(noneId !== null && noneId !== -1
                    && !isAllSelected) || noneIds.length === answers.length}
              />
            </div>
          </div>}
          <ul
            id="questionSet1__list"
            style={{ flexDirection: hasOverflowX ? 'row' : 'column' }}
            className={classNames(s.questionSet1__list, { [s.treatment]: isWriteAllow })}>
            {answers.map((item) => {
              const isNone = noneIds.includes(item.id);
              let validValue = null;
              validValue = isNone ? item.value.split('***')[1] : item.value;
              return (
                <DropdownRow
                  className={classNames({ [s.treatment]: isWriteAllow })}
                  key={item.id}
                  value={validValue}
                  isWriteAllow={isWriteAllow}
                  isActive={item.selected}
                  columnWidth={columnWidth}
                  toggleItem={() => onItemClick(item.id, item.selected, isNone)} />
              );
            })}
          </ul>
          {isWriteAllow && <div className={s.treatmentWrite}>
            <div className={s.treatmentWrite__inner}>
              <div className={s.treatmentWrite__header}>
                <div className={s.treatmentWrite__title}>
                  {question[ALLOW_WRITE_TEXT] && t(question[ALLOW_WRITE_TEXT] || 'Write-In Treatment')} {<>
                    <span className={s.restCharacters}>
                      - {30 - commentInput.length} {t('characters left')}
                    </span>
                  </>}
                </div>
                {/* <CheckboxPure checked={!!commentInput} /> */}
              </div>
              <div className={s.treatmentWrite__body}>
                <textarea
                  type="text"
                  value={commentInput}
                  // disabled={isNoneSelected}
                  onChange={e => e.target.value.length <= 30 && setCommentInput(e.target.value)}
                  onBlur={toggleComment}
                  maxLength={30}
                  className={s.treatmentWrite__input} />
              </div>
            </div>
          </div>}
        </>
      )}
    </section>
  );
};

export default MultipeChoice;
