import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import s from './Menu.module.scss';
import { selectActiveTab, selectInterviewId, setActiveTab } from '../../redux/slices/currentInterviewSlice';
import { getisInterviewPage } from '../../helpers/utils';
import {
  INTERVIEW_TABS_KEYS, PATIENT_BASE_INFO_KEYS, QUESTION_KEYS,
} from '../../constants/names';
import { INTRODUCTION_SHEET, SURVEY_SHEET } from '../../configs/interviewConfigs';
import { useTranslation } from '../../context/LanguageProvider';
import medQpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import greenBubble from '../../assets/nImages/Green_Bubble.svg';
import { setAlert } from '../../redux/slices/alertSlice';

const { INTRODUCTION, SURVEY } = INTERVIEW_TABS_KEYS;
const { ICON, MENU_IMAGE, CHAPTER_NAME } = QUESTION_KEYS;
const { DISPLAY_NAME } = PATIENT_BASE_INFO_KEYS;

const Menu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const interviewId = useSelector(selectInterviewId);
  const activeTab = useSelector(selectActiveTab);
  const isOnlyCase = useSelector(state => state.event.isOnlyCase);
  const {
    sheetContent, id: caseId, uuid, baseInfo,
  } = useSelector(state => state.currentInterview);
  const isAllFinished = useSelector(state => state.currentInterview.finished);
  const activeNavRef = useRef(null);

  const allTabs = useMemo(() => {
    if (!sheetContent) return [ INTRODUCTION_SHEET, SURVEY_SHEET ];
    return [
      INTRODUCTION_SHEET,
      ...sheetContent,
      SURVEY_SHEET,
    ];
  }, [ sheetContent ]);
  const activeTabData = useMemo(() => allTabs
    .find(item => item.title === activeTab), [ activeTab ]);
  const isTablet = useMediaQuery({
    query: '(max-width: 1300px)',
  });

  const getTabIcon = (tab, key = ICON) => {
    if (!tab) return '';
    const icon = tab.data.question[key];
    return [ INTRODUCTION, SURVEY ].includes(tab.title)
      ? icon
      : `${process.env.REACT_APP_IMAGE_URL}images/${icon}`;
  };

  // const activeTabData = allTabs.find(item => item.title === activeTab);
  const activeTabImage = getTabIcon(activeTabData, MENU_IMAGE);

  const finishedTabs = useMemo(() => (
    sheetContent?.filter(sheet => sheet.finished).map(item => item.title) || []
  ), [ sheetContent ]);

  const [ isIntroductionFinished, setIsIntroductionFinished ] = useState(
    activeTab !== INTRODUCTION || finishedTabs.length,
  );

  const getIsFinished = (tab) => {
    if ((tab === INTRODUCTION && isIntroductionFinished) || (tab === SURVEY && isAllFinished)) {
      return true;
    }
    return finishedTabs.includes(tab);
  };

  const isInterviewPage = getisInterviewPage(pathname);

  const onNavClick = (nav, isVisited) => {
    const activeTabContent = [
      INTRODUCTION_SHEET,
      ...sheetContent,
      SURVEY_SHEET,
    ].find(item => item.title === activeTab);
    if ((activeTabContent?.selectionCount === undefined
      || activeTabContent?.selectionCount === 0
      || (activeTabContent?.data?.question?.allowWriteIns === true
        && activeTabContent?.commentCount === 0))
      && (activeTabContent?.data?.question?.chapterType !== 'presentation'
      && activeTabContent?.data?.question?.chapterType !== 'ranking'
      && activeTab !== INTRODUCTION
      && activeTab !== SURVEY)
      && !activeTabContent?.skipChapter
      && !isOnlyCase
    ) {
      return dispatch(
        setAlert({
          message: 'Please make a selection before proceeding to another chapter',
          timer: 5000,
        }),
      );
    }
    if ((nav === INTRODUCTION && isIntroductionFinished)
      || ((isOnlyCase || localStorage.getItem(`${uuid}_${caseId}_terms`) === 'accepted')
        && (finishedTabs.includes(nav) || isVisited))) {
      dispatch(setActiveTab(nav));
    }
  };

  useEffect(() => {
    if (!isIntroductionFinished && (activeTab !== INTRODUCTION || finishedTabs.length)) {
      setIsIntroductionFinished(true);
    }
  }, [ activeTab, finishedTabs ]);

  useEffect(() => {
    activeNavRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
  }, [ activeTab ]);

  return (
    <aside className={s.menu}>
      {!isTablet && <div className={s.headerLogo}>
        <img src={medQpLogo} alt='MedQA' className={s.headerLogo_png} />
      </div>}
      {interviewId && isInterviewPage && (
        <>
          <section className={s.nav_container}>
            <nav className={s.nav}>
              <section className={classNames(s.nav_list_holder, { [s.noQrCode]: isOnlyCase })}>
                <ul className={s.nav__list}>
                  {allTabs.map((navItem, idx) => {
                    const icon = getTabIcon(navItem);
                    return (
                      <li
                        key={idx}
                        ref={navItem.title === activeTab ? activeNavRef : null}
                        className={classNames(s.nav__item, {
                          [s.active]: navItem.title === activeTab,
                          [s.finished]: getIsFinished(navItem.title) && navItem?.visited,
                          [s.clickable]: navItem?.visited && !getIsFinished(navItem.title),
                        })}
                        onClick={() => onNavClick(navItem.title, navItem.visited)}>
                        <div className={classNames(s.nav__prop)}>
                          <div
                            className={classNames(
                              s.nav__icon,
                              getIsFinished(navItem.title) ? s.navPassed : '',
                            )}>
                            <div
                              className={classNames(s.nav__svg, {
                                [s.activeIcon]: activeTab === navItem.title,
                              })}>
                              <img src={icon} alt='' />
                            </div>
                          </div>
                          <div className={s.nav__text}>
                            {navItem.data.question[CHAPTER_NAME] === INTRODUCTION
                              ? baseInfo[DISPLAY_NAME] || t(INTRODUCTION)
                              : t(navItem.data.question[CHAPTER_NAME])}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
              {!isTablet && <div className={s.menu_green_bubble}>
                <img className={s.green_bubble_image} alt='' src={greenBubble} />
              </div>}
            </nav>
            {activeTabImage && !isTablet && (
              <div className={classNames(s.menu__pic)}>
                <img className={s.menu__img} src={activeTabImage} alt={''} />
              </div>
            )}
          </section>
        </>
      )}
    </aside>
  );
};

export default Menu;
