import React from 'react';
import classNames from 'classnames';
import s from './DropdownRow.module.scss';

const DropdownRow = ({
  toggleItem, isActive, value, isWriteAllow, columnWidth,
}) => (
  <li
    style={{ width: `${columnWidth}` }}
    onClick={toggleItem}
    className={classNames(s.dropdownRow__item,
      { [s.active]: isActive, [s.treatment]: isWriteAllow })}>
    <div className={s.dropdownRow__checkbox} />
    <div className={s.dropdownRow__text}>
      {value?.split('\n').map((paragraph, i) => (
        <div key={i} className={s.paragraph}>
          {paragraph}
        </div>
      ))}
    </div>
  </li>
);

export default DropdownRow;
