import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './Welcome.module.scss';
import medqpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import bubbleImage from '../../assets/nImages/orange_bubble.svg';
import { Button } from '../../components/UI';
import { selectEvent } from '../../redux/slices/eventSlice';
import { EVENT_TYPE_NAMES, INTERVIEW_TABS_CHAPTERS } from '../../constants/names';
import { selectInterviewTranslationId, setInterview } from '../../redux/slices/currentInterviewSlice';
import { useTranslation } from '../../context/LanguageProvider';
import { resetCaseThunk } from '../../redux/thunks/eventThunk';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { getPopupLink } from '../../helpers/utils';
import { openPopup } from '../../redux/slices/popupsSlice';

const Welcome = ({ notFound }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const event = useSelector(selectEvent);
  const caseId = useSelector(selectInterviewTranslationId);
  const baseInfo = event.cases ? event.cases[0].baseInfo : {};
  const { shareable } = useSelector(state => state.currentInterview);
  const { isOnlyCase, id, uuid } = event;
  const [ isConfirmActive, setIsConfirmActive ] = useState(false);
  const translation = event.cases ? event.cases[0].translation : {};
  const [ showDisclaimer, setShowDisclaimer ] = useState(false);

  const openConfirm = () => {
    setIsConfirmActive(true);
  };

  const startOver = () => {
    dispatch(resetCaseThunk({ caseId }))
      .unwrap()
      .then(() => setIsConfirmActive(false));
  };

  const acceptDisclaimer = () => {
    if (isOnlyCase) return;
    localStorage.setItem(`${uuid}_${event?.cases[0].id}_terms`, 'accepted');
    if (event.cases.length > 1) {
      return navigate('/interviews');
    }
    let activeTab = INTERVIEW_TABS_CHAPTERS.INTRODUCTION;
    const currentEvent = event.cases[0];
    if (currentEvent.started && !currentEvent.finished) {
      const tabs = currentEvent.sheetContent;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tabs.length; i++) {
        if (!tabs[i].finished) {
          activeTab = tabs[i].title;
          break;
        }
      }
    }
    dispatch(setInterview({ activeTab, ...currentEvent }));
    return navigate(`/interviews/${currentEvent.id}`);
  };

  const [
    isLinkExist, firstTermsText, secondTermsText, termsLink,
  ] = getPopupLink(event?.customDisclaimer ? event?.customDisclaimer : baseInfo.legal);

  const toggleDisclaimer = () => {
    setShowDisclaimer(prev => !prev);
  };

  const onLinkClick = () => {
    dispatch(openPopup({ id: termsLink.popupId }));
  };

  return (
    <section className={s.welcome}>
      <section className={s.welcome_container}>
        <div className={s.welcome__logo}>
          <img className={s.welcome__img} src={medqpLogo} alt='MedQP Logo' />
          {!isOnlyCase && event && event?.eventLogo && (
            <div className={s.welcome_bi_logos}>
              <img
                className={s.welcome__img}
                src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventLogo}`}
                alt='BoehringerIngelheim'
              />
            </div>
          )}
        </div>
        <div className={s.welcome__inner}>
          <div className={s.welcome__title}>
            <div className={s.welcome__subtitle}>
              {translation['Welcome to our Interactive'] || 'Welcome to our Interactive'}
            </div>
            <div className={s.welcome__subtitle}>
              {translation['Digital Patient Case Presentation!']
                || 'Digital Patient Case Presentation!'}
            </div>
            <img className={s.wc_title_bubble} src={bubbleImage} alt='' />
          </div>
          <section className={s.welcome_inner_container}>
            <div className={s.welcome__inner_bottom}>
              {notFound && (
                <div className={s.grayField}>
                  <div className={s.notFound}>
                    {translation['EVENT NOT FOUND'] || 'EVENT NOT FOUND'}
                  </div>
                </div>
              )}
              {!notFound && isOnlyCase && id && !shareable && (
                <div className={s.grayField}>
                  <div className={s.notFound}>
                    {translation['EVENT IS NOT AVAILABLE'] || 'EVENT IS NOT AVAILABLE'}
                  </div>
                </div>
              )}
              {event?.eventImage && <div className={s.welcome_custom_inner}>
                <img
                  className={s.welcome_custom_img}
                  src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventImage}`}
                  alt='BoehringerCustomEvent'
                />
              </div>}
              {!event?.eventImage && !notFound && !isOnlyCase && event.name && !showDisclaimer && (
                <section className={s.event_details_holder}>
                  <section className={s.event_info_holder}>
                    <div className={s.welcome__case_info}>
                      <div className={s.welcome_parent_info}>
                        {t('Patient:')} {baseInfo.gender} {baseInfo.firstName}
                      </div>
                      <div className={s.welcome_sub_info}>{event.therapeuticArea}</div>
                    </div>
                    <div className={s.welcome_event_info}>
                      <div className={s.welcome_parent_info}>
                        {event.name},{' '}
                        {translation[EVENT_TYPE_NAMES[event.eventType]]
                          || EVENT_TYPE_NAMES[event.eventType]}
                      </div>
                      <div className={s.welcome_sub_info}>
                        {dayjs(event.startDate).format('MMMM D, YYYY')}
                      </div>
                    </div>
                    <div className={s.welcome_event_info}>
                      <div className={s.welcome_parent_info}>
                        {event.city && `${event.city}, `}
                        {event.country}
                      </div>
                      <div className={s.welcome_sub_info}>
                        {event.region} {translation['Region'] || 'Region'}
                      </div>
                    </div>
                  </section>
                </section>
              )}
              {!event?.eventImage && !notFound && !isOnlyCase && event.name
                && showDisclaimer && firstTermsText
                && <div className={s.TermsField}>
                  <div className={s.termsText}>
                    {firstTermsText}
                    {isLinkExist && <>
                      <span onClick={onLinkClick} className={s.link}>{termsLink.text}</span>
                      {secondTermsText}
                    </>}
                  </div>
                </div>
              }
            </div>
          </section>
        </div>
        {!showDisclaimer && <div className={s.welcome_footer}>
          {event.finished ? (
            <div className={s.grayField}>
              <div className={s.notFound}>
                {translation['EVENT HAS FINISHED'] || 'EVENT HAS FINISHED'}
              </div>
            </div>
          ) : (
            !notFound && !isOnlyCase && event.name && !showDisclaimer && (
              <Button onClick={toggleDisclaimer} className={s.welcome__button}>
                {translation['Begin'] || 'Begin'}
              </Button>
            )
          )}
        </div>}
        {!notFound && !isOnlyCase && event.name && showDisclaimer
          && <div className={s.disclaimer_footer}>
            <Button onClick={toggleDisclaimer} className={s.disclaimer_back_button}>
              {translation['Back'] || 'Back'}
            </Button>
            <Button onClick={acceptDisclaimer} className={s.disclaimer_accept_button}>
              {translation['Accept'] || 'Accept'}
            </Button>
          </div>
        }
      </section>
      {!notFound && !isOnlyCase && event.name
      && !showDisclaimer && <section className={s.welcomeResetHolder}>
        <div className={s.welcomeResetButton} onClick={openConfirm}>
          {translation['Reset'] || 'Reset'}
        </div>
      </section>}
      {/* MODAL */}
      {isConfirmActive && (
        <ConfirmModal
          onConfirm={startOver}
          onClose={() => setIsConfirmActive(false)}
          loading={event.isStartOverLoading}
        />
      )}
    </section>
  );
};

export default Welcome;
