import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import s from './MultipleChoiceGroups.module.scss';
import { Checkbox } from '../../../../components/UI';
import { setChoicesThunk } from '../../../../redux/thunks/interviewThunk';
import { CHOICE_TYPES, QUESTION_KEYS } from '../../../../constants/names';
import { useTranslation } from '../../../../context/LanguageProvider';
import MCCategory from './component/MCCategory/MCCategory';
import { selectActiveTab, setSectionStarted, setSkippedChapters } from '../../../../redux/slices/currentInterviewSlice';
import MultipeChoiceSummary from './component/MultipeChoiceSummary/MultipeChoiceSummary';

const {
  SELECT_ALL, ALLOW_WRITE, ALLOW_WRITE_TEXT,
} = QUESTION_KEYS;
const MultipleChoiceGroups = ({ isSummaryPage, data, skipChapter }) => {
  const dispatch = useDispatch();
  const { t, key_t } = useTranslation();
  const {
    answers, answersCategories, question, comment = '',
  } = data.data;
  const activeTab = useSelector(selectActiveTab);
  const isSelectAllExist = data.data.question[SELECT_ALL] === key_t('YES');
  const [ activeCategories, setActiveCategories ] = useState([]);
  const [ commentInput, setCommentInput ] = useState(comment);
  const isWriteAllow = question[ALLOW_WRITE];

  const allCategories = Object.entries(answers);
  const toggleCategory = (name) => {
    if (activeCategories.includes(name)) {
      return setActiveCategories(prev => prev.filter(item => item !== name));
    }
    return setActiveCategories(prev => [ ...prev, name ]);
  };

  const itemsData = useMemo(() => {
    let allItemsLength = 0;
    const selectedItems = [];
    let is_none_selected = false;
    let none_id = -1;
    const exclusivity_ids = [];
    if (Array.isArray(answers)) return { selectedItems, allItemsLength };
    const dataEntries = Object.values(answers);
    dataEntries.forEach((item) => {
      item.forEach((el) => {
        allItemsLength += 1;
        if (el.selected) {
          selectedItems.push(el);
        }
        if (el[answersCategories[1]].includes('***')) {
          if (el.selected) {
            none_id = el.id;
            is_none_selected = el.selected;
          }
          exclusivity_ids.push(el.id);
        }
      });
    });
    return {
      selectedItems,
      allItemsLength,
      isNoneSelected: is_none_selected,
      noneId: none_id,
      noneIds: exclusivity_ids,
    };
  }, [ answers ]);

  const isAllSelected = itemsData.selectedItems.length === itemsData.allItemsLength;
  const isAllExclusivity = itemsData.noneIds.length === itemsData.allItemsLength;

  const onAllClick = () => {
    if (!isAllSelected) {
      setActiveCategories(Object.keys(answers));
    }
    const operation = isAllSelected ? 0 : 1;
    const value = { optionId: 'all' };
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.OPTION, operation, value }));
  };

  useEffect(() => {
    if ((question?.useCategory && question?.expandCategory)) {
      Object.entries(answers).forEach((item) => {
        if (!activeCategories.includes(item[0])) {
          toggleCategory(item[0]);
        }
      });
    }
  }, [ question?.chapterName ]);

  const toggleComment = () => {
    if (commentInput === comment) return;
    if (skipChapter) {
      dispatch(setSkippedChapters());
    }
    const operation = commentInput ? 1 : 0;
    const value = {
      comment: commentInput,
    };
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.COMMENT, operation, value }));
  };

  useEffect(() => {
    if (commentInput && !itemsData?.selectedItems.length) {
      dispatch(setSectionStarted({ section: activeTab, operation: true }));
    } else if (!commentInput && !itemsData?.selectedItems.length) {
      dispatch(setSectionStarted({ section: activeTab, operation: false }));
    }
  }, [ commentInput ]);

  useEffect(() => {
    if (skipChapter) {
      setCommentInput('');
    }
  }, [ skipChapter ]);

  return (
    <section className={s.mcGroupSectionView}>
      {isSummaryPage ? (
        <MultipeChoiceSummary
          comment={comment}
          selectedItems={itemsData.selectedItems}
          title={data.title}
          noneIds={itemsData.noneIds} />
      ) : (
        <>
          <div className={classNames(s.mcGroupContainer)}>
            <div className={s.mcChoiceHolder}>
              {isSelectAllExist && (
                <div
                  className={classNames(s.questionSet1__selectAll,
                    s.questionSet1__selectAll_groups)}>
                  <div className={s.questionSet1__selectInner}>
                    <Checkbox
                      isDisabled={isAllExclusivity}
                      checked={isAllSelected}
                      onChange={() => onAllClick()}
                      title={t(`${isAllSelected ? 'Remove' : 'Select'} All`)}
                    />
                  </div>
                </div>
              )}
              <ul className={s.mcGroupList}>
                {allCategories.map(category => (
                  <MCCategory
                    key={category[0]}
                    title={category[0]}
                    items={category[1]}
                    isActive={activeCategories.includes(category[0])}
                    toggleCategory={() => toggleCategory(category[0])}
                    checkboxKey={answersCategories[1]}
                    columnWidth={`${question?.columnWidth}` || 200}
                    skipChapter={skipChapter}
                    mcInfo={itemsData}
                    isWriteAllow={question[ALLOW_WRITE]}
                  />
                ))}
              </ul>
            </div>
            {isWriteAllow && <div className={s.treatmentWrite}>
              <div className={s.treatmentWrite__inner}>
                <div className={s.treatmentWrite__header}>
                  <div className={s.treatmentWrite__title}>
                    {question[ALLOW_WRITE_TEXT] && t(question[ALLOW_WRITE_TEXT] || 'Write-In Treatment')} {<>
                      <span className={s.restCharacters}>
                        - {30 - commentInput.length} {t('characters left')}
                      </span>
                    </>}
                  </div>
                  {/* <CheckboxPure checked={!!commentInput} /> */}
                </div>
                <div className={s.treatmentWrite__body}>
                  <textarea
                    type="text"
                    value={commentInput}
                    // disabled={isNoneSelected}
                    onChange={e => e.target.value.length <= 30 && setCommentInput(e.target.value)}
                    onBlur={toggleComment}
                    maxLength={30}
                    className={s.treatmentWrite__input} />
                </div>
              </div>
            </div>}
          </div>
        </>
      )}
    </section>
  );
};

export default MultipleChoiceGroups;
