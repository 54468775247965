import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import s from './InterviewItem.module.scss';
import questionIcon from '../../../../../../assets/nImages/Doctor.png';
import { getPatientDefaultImage } from '../../../../../../helpers/utils';

const InterviewItem = ({ data, onItemClick, keys }) => {
  const { avatar, gender } = useSelector(state => state.currentInterview);
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  return (
    <li
      className={s.anamnesisDropdown__item}
      onClick={onItemClick}>
      {isMobile && data.selected ? (
        <div className={s.anamnesisDropdown__answer}>
          <div className={s.anamnesisDropdown__content}>
            <div className={s.anamnesisDropdown__avatar}>
              <img src={questionIcon} alt="" className={s.anamnesisDropdown__img} />
            </div>
            <div className={s.anamnesisDropdown__text}>
              {data[keys[1]]?.split('\n').map((paragraph, i) => (
                <p key={i} className='paragraph'>{paragraph}</p>
              ))}
            </div>
          </div>
          <div className={s.anamnesisDropdown__content}>
            <div className={s.anamnesisDropdown__avatar}>
              <img
                src={avatar
                  ? process.env.REACT_APP_IMAGE_URL + avatar : getPatientDefaultImage(gender)}
                alt=""
                className={s.anamnesisDropdown__img} />
            </div>
            <div className={s.anamnesisDropdown__text}>
              {data[keys[2]]?.split('\n').map((paragraph, i) => (
                <p key={i} className='paragraph'>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames(s.anamnesisDropdown__question, {
          [s.anamnesisDropdown_question_selected]: data.selected,
        })}>
          {data[keys[1]]?.split('\n').map((paragraph, i) => (
            <p key={i} className='paragraph'>{paragraph}</p>
          ))}
        </div>
      )}
    </li>
  );
};

export default InterviewItem;
