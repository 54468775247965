import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import s from './InterviewSummary.module.scss';
import doctorIcon from '../../../../../../assets/nImages/Doctor.png';
import Table from '../../../../../../components/UI/Table/Table';
import { getPatientDefaultImage } from '../../../../../../helpers/utils';
import { useTranslation } from '../../../../../../context/LanguageProvider';

const InterviewSummary = ({ selectedItems, answersCategories = []}) => {
  const { avatar, gender } = useSelector(state => state.currentInterview);
  const { t } = useTranslation();

  const icons = [
    null,
    doctorIcon,
    avatar ? process.env.REACT_APP_IMAGE_URL + avatar : getPatientDefaultImage(gender),
  ];

  return (
    <div className={s.anamnesisSummary}>
      <Table className={s.anamnesis_table}>
        <Table.Header>
          {answersCategories.map((header, i) => (
            <Table.HeaderCell key={header} length={answersCategories.length}>
              <div className={s.anamnesisSummary__header}>
                {icons[i] && <div className={s.anamnesisSummary__icon}>
                  <img src={icons[i]} alt="avatar" className={s.anamnesisSummary__img} />
                </div>}
                <div className={s.anamnesisSummary__title}>
                  {t(header)?.split('\n').map((paragraph, idx) => (
                    <p key={idx} className='header_paragraph'>{paragraph}</p>
                  ))}
                </div>
              </div>
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body className={s.anamnesis_table_body}>
          {selectedItems.map(row => (
            <Table.Row key={row.id}>
              {answersCategories.map((item, i) => (
                <Table.Cell
                  title={<div>
                    {icons[i] && <div className={s.anamnesisSummary__icon}>
                      <img src={icons[i]} alt="avatar" className={s.anamnesisSummary__img} />
                      </div>}
                    <div className={s.anamnesisSummary__title}>
                      {item?.split('\n').map((paragraph, idx) => (
                        <p key={idx} className='header_paragraph'>{t(paragraph)}</p>
                      ))}
                    </div>
                  </div>}
                  key={item + row.id}
                  length={answersCategories.length}>
                  <div className={classNames(s.anamnesisSummary__info,
                    { [s.firstChild]: !i })}>
                    {row[item]?.split('\n').map((paragraph, idx) => (
                      <p key={idx} className='paragraph'>{paragraph}</p>
                    ))}
                  </div>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default InterviewSummary;
