import React from 'react';
import classNames from 'classnames';
import s from '../../../pages/Interview/Templates/Ranking/components/DragRow/DragRow.module.scss';

const CardRow = ({
  checked, number, drag, isIdentHidden, children,
}) => (
  <li className={classNames(s.cardRow, drag && s.drag)}>
    {number && (
      <div className={s.cardRow__number}>{number}</div>
    )}
    <div className={s.cardRow__info}>
      <div className={s.cardRow__text}>{children}</div>
      {drag && (
        <div className={s.cardRow__drag}>
          <svg className={s.cardRow__drag__icon}>
            <use xlinkHref="#drag" />
          </svg>
        </div>
      )}
    </div>
  </li>
);

export default CardRow;
