import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHOICE_TYPES } from '../../../../../constants/names';
import { useTranslation } from '../../../../../context/LanguageProvider';
import { setChoicesThunk } from '../../../../../redux/thunks/interviewThunk';
import s from '../Survey.module.scss';

const SurveyComments = ({ title, app }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const comment = '';
  const [ commentInput, setCommentInput ] = useState(comment);

  const toggleComment = () => {
    if (commentInput === comment) return;
    const operation = commentInput ? 1 : 0;
    const value = {
      feedback: commentInput,
    };
    dispatch(setChoicesThunk({ type: CHOICE_TYPES.FEEDBACK, operation, value }));
  };
  return (
    <div className={s.survey__comment}>
      <div className={s.comment__header}>
        <div className={s.comment_card}>{t(title)}</div>
      </div>
      <div className={s.comment__body}>
        <textarea
          type="text"
          value={commentInput}
          // disabled={isNoneSelected}
          onChange={e => setCommentInput(e.target.value)}
          onBlur={toggleComment}
          maxLength={1000}
          className={s.comment__input} />
      </div>
    </div>
  );
};

export default SurveyComments;
