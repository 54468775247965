import React from 'react';
import s from './Stars.module.scss';

const Stars = ({ value, onStarClick }) => (
  <div className={s.stars} >
    {Array.from(Array(5), (_, i) => (
      <div key={i} className={s.star} onClick={() => onStarClick(i + 1)}>
        <svg className={s.star__svg} fill={i < value ? '#4FB1C2' : 'transparent'} stroke={value ? '#4FB1C2' : '#969393'} strokeWidth='1.5px'>
          <use xlinkHref={'#star'} />
        </svg>
      </div>
    ))}
  </div>
);

export default Stars;
