import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import s from './Interview.module.scss';
import Heading from '../../components/Heading/Heading';
import { Button } from '../../components/UI';
import { setActiveTab, setSkippedChapters } from '../../redux/slices/currentInterviewSlice';
import {
  getTemplateConfig, INTRODUCTION_SHEET, SURVEY_SHEET,
} from '../../configs/interviewConfigs';
import {
  CHOICE_TYPES, INTERVIEW_TABS_KEYS, QUESTION_KEYS, TEMPLATE_TYPES,
} from '../../constants/names';
import { setChoicesThunk } from '../../redux/thunks/interviewThunk';
import { setAlert } from '../../redux/slices/alertSlice';
import { useInterviewSideEffects } from './hooks/interview.hooks';
import { useTranslation } from '../../context/LanguageProvider';

const { INTRODUCTION, SURVEY } = INTERVIEW_TABS_KEYS;
const {
  CHAPTER_TYPE, BANNER_IMAGE, SKIP_CHAPTER, SKIP_CHAPTER_TEXT,
} = QUESTION_KEYS;
const { RANKING, IMAGE_INTERACTIVE } = TEMPLATE_TYPES;
const { SECTION, CASE, PRESENTATION } = CHOICE_TYPES;

const Interview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useInterviewSideEffects();
  const [ isSummaryPage, setIsSummeryPage ] = useState(false);
  const {
    sheetContent,
    rate,
    appRate,
    activeTab,
    isNextLoading,
    skippedChapters,
  } = useSelector(state => state.currentInterview);

  const allTabs = useMemo(() => {
    if (!sheetContent) return [ INTRODUCTION_SHEET, SURVEY_SHEET ];
    return [
      INTRODUCTION_SHEET,
      ...sheetContent,
      SURVEY_SHEET,
    ];
  }, [ sheetContent ]);

  const notStartedTabs = useMemo(() => {
    const tabs = [];
    if (activeTab === SURVEY) {
      sheetContent.forEach(item => item.data.question[CHAPTER_TYPE] !== RANKING
        && item.data.question[CHAPTER_TYPE] !== PRESENTATION
        && (!item.started && !item?.skipChapter) && tabs.push(item.title));
    }
    return tabs;
  }, [ activeTab ]);

  const isFinishDisabled = activeTab === SURVEY && !!notStartedTabs.length;
  const activeTabData = allTabs.find(item => item.title === activeTab);
  const activeTabConfigs = getTemplateConfig(activeTabData);
  const isChapterRanking = activeTabData.data.question[CHAPTER_TYPE] === RANKING;

  const headingInfo = activeTabData || {};
  const headingImage = useMemo(() => getHeadingImage(), [ activeTabData ]);
  const skipChapter = useMemo(() => skippedChapters.includes(activeTab),
    [ skippedChapters, activeTab ]);

  const nextButtonText = (!isSummaryPage
    && !skipChapter
    && !!activeTabData?.data?.question?.showSummary
    && activeTabConfigs.summaryText)
    || (activeTab === SURVEY && 'Finish') || 'Next';
  const isNextButtonDisabled = (activeTabConfigs.summaryText !== undefined
    && !isSummaryPage && !activeTabData.started
    && activeTabData.data.question[CHAPTER_TYPE] !== RANKING
    && activeTabData.data.question[CHAPTER_TYPE] !== PRESENTATION
    && !skipChapter)
    || (activeTab === SURVEY && (!rate || !appRate))
    || (activeTabData.data.question[CHAPTER_TYPE] === IMAGE_INTERACTIVE
      && (!activeTabData.started && !skipChapter));

  const handleSkipChapter = () => {
    dispatch(setSkippedChapters());
  };

  const onNextClick = () => {
    if (isFinishDisabled) {
      return dispatch(setAlert({
        message: `${notStartedTabs.join(', ')} tab${notStartedTabs.length > 1 ? 's' : ''} ${notStartedTabs.length > 1 ? "don't" : "doesn't"} have choice`,
        timer: 5000,
      }));
    }
    if (activeTabConfigs.summaryText && !isSummaryPage && !skipChapter
      && !!activeTabData?.data?.question?.showSummary) {
      return setIsSummeryPage(true);
    }
    if (activeTab === allTabs[allTabs.length - 1].title) {
      return dispatch(setChoicesThunk({
        type: CASE,
      }));
    }
    if ((activeTab === INTRODUCTION || activeTabData.finished)
    && activeTabData.data.question[CHAPTER_TYPE] !== RANKING) {
      setIsSummeryPage(false);
      return dispatch(setActiveTab(getActiveTab(true)));
    }
    return dispatch(setChoicesThunk({
      type: SECTION, nextTab: getActiveTab(true),
    }));
  };

  useEffect(() => () => setIsSummeryPage(false), [ activeTab ]);

  return (
    <div className={s.interviewPanel}>
      <div className={s.interview}>
        <div
          className={classNames(s.interviewHeading, {
            [s.interviewRanking]: isChapterRanking,
            [s.introHeading]: activeTab === INTRODUCTION,
          })}>
          <Heading data={headingInfo} headingImage={headingImage} isRanking={isChapterRanking} />
        </div>
        <div
          className={classNames(s.interviewWrapper, {
            [s.interviewRanking]: isChapterRanking,
            [s.introBody]: activeTab === INTRODUCTION,
          })}>
          <div
            className={classNames(
              s.interviewPageContainer,
              activeTabData.data.question[CHAPTER_TYPE] === IMAGE_INTERACTIVE
                ? s.physicalExamInterview
                : s.otherInterview,
            )}>
            <div className={s.interviewPages}>
              <div
                className={classNames(s.interviewPageContainer, {
                  [s.skip_chapter_active]: activeTabData.data?.question[SKIP_CHAPTER],
                })}>
                {!!activeTabConfigs.Component && (
                  <activeTabConfigs.Component
                    data={activeTabData}
                    isSummaryPage={isSummaryPage}
                    skipChapter={skipChapter}
                  />
                )}
              </div>
              {!isSummaryPage
                && activeTabData.data.question[CHAPTER_TYPE] !== PRESENTATION
                && activeTabData.data?.question[SKIP_CHAPTER] && (
                <div
                  className={classNames(s.interview_skip_holder, {
                    [s.skip_chapter_active]: skipChapter,
                  })}
                  onClick={handleSkipChapter}>
                  <div className={s.skip_checkbox} />
                  <div className={s.skip_text}>
                    {t(activeTabData.data?.question[SKIP_CHAPTER_TEXT])}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={s.interview__shift}>
        <div className={s.interview__modify}>
          {activeTab !== INTRODUCTION && (
            <Button onClick={() => dispatch(setActiveTab(getActiveTab()))}>Previous</Button>
          )}
        </div>
        <div>
          {isSummaryPage && activeTabConfigs.summaryText && (
            <Button
              variant='modify'
              className={s.modifyButton}
              onClick={() => setIsSummeryPage(false)}>
              Modify
            </Button>
          )}
          <Button
            loading={isNextLoading}
            disabled={isNextButtonDisabled}
            onClick={onNextClick}
            variant={!isSummaryPage && activeTabConfigs.summaryText ? 'light' : 'primary'}>
            {nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );

  function getActiveTab(isPlus) {
    const tabIndex = allTabs.findIndex(item => item.title === activeTab);
    const futureTabIndex = isPlus ? tabIndex + 1 : tabIndex - 1;
    return allTabs[futureTabIndex].title;
  }

  function getHeadingImage() {
    if (!activeTabData || activeTabData.title === INTRODUCTION) return '';
    const icon = activeTabData.data.question[BANNER_IMAGE];
    return [ SURVEY ].includes(activeTabData.title)
      ? icon
      : `${process.env.REACT_APP_IMAGE_URL}images/${icon}`;
  }
};

export default Interview;
