import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INTERVIEW_TABS_CHAPTERS } from '../../constants/names';
import { useTranslation } from '../../context/LanguageProvider';
import { getPatientDefaultImage } from '../../helpers/utils';
import { resetChatData, setInterview } from '../../redux/slices/currentInterviewSlice';
import { selectEvent } from '../../redux/slices/eventSlice';
import s from './FinalPage.module.scss';
import medqpLogo from '../../assets/nImages/MEDQA_LOGO.svg';
import welcomeIcon from '../../assets/images/welcome.svg';
import { Button } from '../../components/UI';

const FinalPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    avatar, gender, uuid, nextCase,
  } = useSelector(state => state.currentInterview);
  const event = useSelector(selectEvent);

  const nextCaseData = useMemo(() => {
    if (!nextCase) return null;
    const currentEvent = event.cases.find(item => item.id === nextCase);
    if (!currentEvent || currentEvent.finished) return null;
    return currentEvent;
  }, [ nextCase, event.cases ]);

  const clickHandler = () => {
    navigate('/interviews');
  };

  const onNextCase = () => {
    let activeTab = INTERVIEW_TABS_CHAPTERS.INTRODUCTION;
    if (nextCaseData.started && !nextCaseData.finished) {
      const tabs = nextCaseData.sheetContent;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tabs.length; i++) {
        if (!tabs[i].finished) {
          activeTab = tabs[i].title;
          break;
        }
      }
    }
    dispatch(setInterview({ activeTab, ...nextCaseData }));
    dispatch(resetChatData([]));
    navigate(`/interviews/${nextCaseData.id}`);
  };

  useEffect(() => {
    if (event.finished && !event.isOnlyCase) {
      localStorage.removeItem(uuid);
    }
  }, [ event.finished ]);

  return (
    <section className={s.finalPage}>
      <section className={s.final_container}>
        <div className={s.final__logo}>
          <img className={s.final__img} src={medqpLogo} alt='MedQP Logo' />
          {event && (event.isOnlyCase || !event?.eventLogo) && (
            <img className={s.final__welcome_img} src={welcomeIcon} alt='Boehringer Ingelheim' />
          )}
          {event && !event.isOnlyCase && event?.cases && event?.eventLogo && (
            <div className={s.final_bi_logos}>
              <img
                className={s.final__welcome_img}
                src={`${process.env.REACT_APP_IMAGE_URL}${event?.eventLogo}`}
                alt='BoehringerIngelheim'
              />
            </div>
          )}
        </div>
        <div className={s.finalPage__inner}>
          <div className={s.finalPage_header} />
          <section className={s.finalPage_body}>
            <section className={s.finalPage_Content}>
              <section className={s.finalPage_StarBg}>
                <div className={s.finalPage__image}>
                  <img
                    src={
                      avatar
                        ? process.env.REACT_APP_IMAGE_URL + avatar
                        : getPatientDefaultImage(gender)
                    }
                    alt='Congratulations!'
                    className={s.finalPage__img}
                  />
                </div>
                <div className={s.finalPage__info}>
                  <div className={s.finalPage__title}>{t('Congratulations!')}</div>
                  <div className={s.finalPage__subtitle}>{t('Case has been completed.')}</div>
                </div>
              </section>
            </section>
          </section>
        </div>
        <div className={s.actions}>
          {nextCaseData && !event.isOnlyCase && (
            <Button className={s.next} onClick={onNextCase} next>
              {t('Start Next Visit')}
            </Button>
          )}
          {!event.finished && !event.isOnlyCase && (
            <Button onClick={clickHandler} next>
              {t('Event Patient Cases')}
            </Button>
          )}
        </div>
      </section>
    </section>
  );
};

export default FinalPage;
