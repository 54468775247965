import classNames from 'classnames';
import { React } from 'react';
import { useSelector } from 'react-redux';
import { getPatientDefaultImage } from '../../../../../../helpers/utils';
import s from './InterviewChatBox.module.scss';
import questionIcon from '../../../../../../assets/nImages/Doctor.svg';

const InterviewChatBox = ({ item }) => {
  const { avatar, baseInfo } = useSelector(state => state.currentInterview);
  return (
    <div className={s.chatWrapper}>
      <div className={classNames(s.chatBox, s.chatQuestion)}>
        <div className={s.chatQuestionItem}>{item?.Question}</div>
        <img src={questionIcon} alt='Doctor' className={s.chatDoctor_img} />
      </div>
      <div className={classNames(s.chatBox, s.chatAnswer)}>
        <img
          src={avatar
            ? process.env.REACT_APP_IMAGE_URL + avatar
            : getPatientDefaultImage(baseInfo.gender)}
          alt='Doctor'
          className={s.chatDoctor_img}
        />
        <div className={s.chatAnswerItem}>{item?.Answer}</div>
      </div>
    </div>
  );
};

export default InterviewChatBox;
