import React from 'react';
import classNames from 'classnames';
import s from './MultipeChoiceSummary.module.scss';
import { useTranslation } from '../../../../../../context/LanguageProvider';

const MultipeChoiceSummary = ({
  selectedItems, comment, title, noneIds,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.questionSet1Summary}>
      <ul className={s.questionSet1Summary__list}>
        {selectedItems.map((item) => {
          const validValue = item.value.includes('***') ? item.value.split('***')[1] : item.value;
          return (<li
            key={item.id}
            checked
            className={classNames(s.questionSet1List)}>
            {noneIds.includes(item.id) ? validValue : item.value?.split('\n').map((paragraph, i) => (
              <p key={i} className='paragraph'>{paragraph}</p>
            ))}
          </li>);
        })}
        {comment
          && <li className={classNames(s.questionSet1List)} checked>
            <div>
              <div className={s.commetHeader}>{t('Write-In Treatment')}</div>
              <div className={s.comment}>{comment}</div>
            </div>
          </li>}
      </ul>
    </div>
  );
};

export default MultipeChoiceSummary;
